.author {
    font-style: 14px;
}
.actionGroup {
    display: flex;
    height: 40px;
    /* margin: 10px 0; */
    margin-top: 8px;

}
.actionGroup>div {
    width: 120px;
}
.replyText {
    font-style: 14px;
    color: #8E939F;
}
.replyCommentAuthor {
    font-style: 14px;
}
.replyCommentComponent {
    display: flex;
    flex-direction: column;
}
.replyCommentComponentTop {
    display: flex;
}
.replyLeftPart {
    margin: 0 auto 0 0;
}
.replyRightPart {
    width: 117px;
    display: flex;
    height: 40px;
}
.replyRightPart>div:first-child{
    margin-right: 34px;
}
.pointer {
    cursor: pointer;
}
.replyContainer {
    margin-top: 16px;
}
.replyLi:not(:last-child) {
    margin-bottom: 23px;
}

@media (max-width: 960px) {
    .author {
        font-style: 13px !important;
    }
}